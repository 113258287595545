.fade-in-section {
  opacity: 0;
  /* transform: translateY(20vh); */
  visibility: hidden;
  transition: opacity 800ms ease-out, transform 600ms ease-out,
    visibility 800ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
